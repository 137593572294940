<template>
  <div class="w_auto">
    <!-- <BreadcrumbNav></BreadcrumbNav> -->
    <!-- 报名已结束 -->
    <div class="coursedet-img" v-if="detail.joinWay != 3">
      <el-row>
        <el-col :span="24">
          <div class="coursedet-listimg">
            <img :src="detail.facePic" alt="" />
          </div>
          <div class="coursedet-bot flex-between">
            <div class="coursedet-imgl">
              <p class="coursedet-tit">
                {{ detail.courseName }} 
                <em v-if="detail.joinWay == 1">口令</em>
                <em v-if="detail.joinWay == 2">指定</em>
                <em v-if="detail.joinWay == 3">收费</em>
                <em v-if="detail.joinWay == 0">免费</em>
              </p>
              <p>
                <span>最近在学:{{ numberList.studyNumber }}人</span>
                <span>累计报名:{{ numberList.joinNumber }}人</span>
                <el-popover
                  placement="bottom"
                  width="300"
                  trigger="hover"
                  class="share"
                >
                  <div>
                    <el-divider>分享给朋友</el-divider>
                    <el-row>
                      <el-col :span="12">
                        <div><img src="@/assets/images/onlinecourses/erweima.png" alt=""></div>
                      </el-col>
                      <el-col :span="12" style="line-height:100px;display:flex;">
                        <div v-for="(i,index) in shareImgList" :key="index">
                          <img :src="i.img" alt="" style="cursor: pointer;padding-right:5px">
                        </div>
                      </el-col>
                    </el-row>
                  </div>
                  <el-button type="text" icon="el-icon-share" slot="reference"
                    >分享</el-button
                  >
                </el-popover>
              </p>
            </div>
            <div class="coursedet-imgbut">
              <el-button v-if="detail.isJoin==0&&detail.courseState==1" icon="el-icon-plus" type="primary" @click="enrolmentClick">报名课程</el-button>
              <el-button v-else-if="detail.isJoin==1&&detail.courseState==1" icon="el-icon-check" type="info" class="signed">已经报名</el-button>
              <el-button v-else-if="detail.courseState==0" type="info" class="signed">课程未开始</el-button>
              <el-button v-if="detail.courseState==2" type="info" class="signed">课程已结束</el-button>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
    <div
      class="shadow radius5 pad20 mt15 bgwhite"
      style="display: flex; justify-content: flex-start"
      v-else
    >
      <div class="chargeimg">
        <div class="coursedet-listimg">
          <img style="height: 2.5rem" :src="detail.facePic" alt="" />
        </div>
      </div>
      <div class="chargetxt">
        <h3>{{ detail.courseName }}</h3>
        <p class="line35">
          <span>最近在学：{{ numberList.studyNumber }}人</span>
          <span>累计报名：{{ numberList.joinNumber }}人</span>
          <el-divider direction="vertical"></el-divider>
          <!-- <span>分享</span> -->
          <el-popover
            placement="bottom"
            width="300"
            trigger="hover"
            class="share"
          >
            <div>
              <el-divider>分享给朋友</el-divider>
              <el-row>
                <el-col :span="12">
                  <div><img src="@/assets/images/onlinecourses/erweima.png" alt=""></div>
                </el-col>
                <el-col :span="12" style="line-height:100px;display:flex;">
                  <div v-for="(i,index) in shareImgList" :key="index">
                    <img :src="i.img" alt="" style="cursor: pointer;padding-right:5px">
                  </div>
                </el-col>
              </el-row>
            </div>
            <el-button
              type="text"
              icon="el-icon-share"
              slot="reference"
              style="color: #555"
              >分享</el-button
            >
          </el-popover>
        </p>
        <p class="line35">讲师：{{ detail.teacherName }}</p>
        <p class="label">
          <span
            ><el-tag type="success"
              >{{ numberList.joinNumber }}人最近购买</el-tag
            ></span
          >
        </p>
        <div>
          <el-row class="flex-between chargepay">
            <el-col :span="24" class="bgf4 pad5 radius5">
              <span class="red">￥{{ detail.salePrice }}</span>
            </el-col>
          </el-row>
          <el-row class="flex-between mt15">
            <el-col :span="24">
              <el-button v-if="detail.isJoin==0" type="primary" v-model="buy" @click="buyClick">立即购买</el-button>
              <el-button v-if="detail.isJoin==1" icon="el-icon-check" disabled>已购买</el-button>
              <el-button v-if="detail.courseState==2" icon="el-icon-check" disabled>课程已结束</el-button>
            </el-col>
          </el-row>
        </div>
      </div>
    </div>
    <!-- 购买课程 -->
    <div class="coursedetail mt15">
      <el-row :gutter="20">
        <el-col :span="16">
          <div>
            <!-- 课程说明 -->
            <div class="coursedet-jj pad20 bgwhite shadow mb15">
              <div class="coursedet-l-t">
                <img
                  style="line-height: 10px"
                  src="@/assets/images/onlinecourses/round.png"
                  alt=""
                />
                课程说明
              </div>
              <div class="coursedet-l-tw">
                <div class="coursedet-l-twtxt">{{ detail.courseDesc }}</div>
              </div>
            </div>
            <!-- 章节列表 -->
            <div class="coursedet-cont bgwhite shadow pad20">
              <el-tabs v-model="activeName" @tab-click="handleClick">
                <el-tab-pane label="章节学习" name="first">
                  <div class="collapse">
                    <el-collapse
                      v-model="activeNames"
                      v-for="(item, index) in chapterList"
                      :key="index"
                    >
                      <el-collapse-item :name="index">
                        <template slot="title">
                          <img
                            style="margin-right: 10px; width: 10px"
                            src="@/assets/images/onlinecourses/round.png"
                            alt=""
                          />{{ item.chapterName }}
                          <span style="font-weight: 500">
                            (共{{ item.sumClassHours }}学时，已学习{{
                              item.studyClassHours
                            }})</span
                          >
                        </template>
                        <div
                          class="bgf4"
                          v-for="(i, index) in item.subMap"
                          :key="index"
                        >
                          <div class="course-li-left" style="font-size: 22px">
                            <i
                              style="margin-right: 10px"
                              class="el-icon-paperclip"
                            ></i>
                            <span class="sketch">{{ i.chapterName }}</span>
                          </div>
                          <div>
                            <el-button
                              v-if="(i.resourceType == 0)"
                              type="primary"
                              round
                              size="mini"
                              @click="previewClick(i.resourceId)"
                              >预览</el-button
                            >
                            <el-button
                              v-else-if="(i.resourceType == 1)"
                              type="primary"
                              plain
                              @click="intoStudy(i.courseId,i.chapterId,i.resourceId,i.resourceType,activityId)"
                              >进入学习</el-button
                            >
                            <el-button v-else type="primary" round size="mini" @click="playClick(i.resourceId)"
                              >播放</el-button
                            >
                          </div>
                        </div>
                      </el-collapse-item>
                    </el-collapse>
                  </div>
                </el-tab-pane>
                <el-tab-pane label="课后练习" name="second">
                  <div v-if="practiceList.length != 0">
                    <div
                      class="bgf4"
                      v-for="(item, index) in practiceList"
                      :key="index"
                    >
                      <div class="course-li-left" style="font-size: 22px">
                        <i
                          style="margin-right: 10px"
                          class="el-icon-edit-outline"
                        ></i>
                        <div>
                          <div style="display: flex; flex-direction: column">
                            <span class="sketch">{{ item.papersName }}</span>
                            <span class="validity"
                              >有效期: {{ item.examinationStartTime }}~{{
                                item.examinationEndTime
                              }}</span
                            >
                          </div>
                        </div>
                      </div>
                      <div style="display: flex; align-items: center">
                        <div class="score">
                          得分：<span>{{ item.getScore }}</span>
                        </div>
                        <!-- <a @click="startPracticing(item.papersId)">
                          <el-button type="primary" plain>开始练习</el-button>
                        </a> -->
                         <!-- <el-button type="primary" plain @click="startPracticing(item.papersId)">开始练习</el-button> -->

                        <el-button type="primary" v-if="item.isConduct==1&&item.answerNumber>item.practiceNum" plain @click="startPracticing(item.papersId)">开始练习</el-button>
                        <el-button type="primary" v-if="(item.isSubmitPaper==1&&item.userPapersId)||(item.isConduct==0&&item.isSubmitPaper==0&&item.userPapersId)" plain @click="showPracticing(item.userPapersId,false,item.papersId)">查看练习</el-button>
                        <el-button type="primary" v-if="item.isConduct==1&&item.isSubmitPaper==0&&item.userPapersId" plain @click="showPracticing(item.userPapersId,true,item.papersId)">继续练习 </el-button>
                      </div>
                    </div>
                  </div>
                  <div v-else>
                    <el-empty description="暂无练习"></el-empty>
                  </div>
                </el-tab-pane>
                <el-tab-pane label="在线提问" name="third">
                  <div>
                    <div
                      class="comment-form"
                      style="display: flex; margin-top: 1%"
                    >
                      <div
                        style="margin-right: 2%"
                        v-if="value && value.headPortrait"
                      >
                        <el-avatar :src="value.headPortrait"></el-avatar>
                      </div>
                      <div v-else style="margin-right: 2%">
                        <el-avatar size="large" :src="circleUrl"></el-avatar>
                      </div>
                      <el-input
                        style="margin-right: 2%"
                        v-model="questionsinput"
                        placeholder="请输入内容"
                        @input="tiwen"
                      ></el-input>
                      <el-button
                        type="success"
                        :disabled="disabled"
                        @click="questionsClick"
                        >提问</el-button
                      >
                    </div>
                    <div v-for="(i, index) in questionList" :key="index">
                      <div class="comment-form onehf">
                        <div class="userimg">
                          <a
                            href="javascript:void();"
                            v-if="i.headPortrait != null"
                            ><el-avatar
                              style="margin-right: 10px"
                              :src="i.headPortrait"
                            ></el-avatar
                          ></a>
                          <div v-else>
                            <el-avatar
                              style="margin-right: 10px"
                              size="large"
                              :src="circleUrl"
                            ></el-avatar>
                          </div>
                          <div class="userinfo">
                            <p class="name">
                              <span>{{ i.userName }}</span>
                            </p>
                            <p>{{ i.updateTime }}</p>
                          </div>
                        </div>
                        <div>
                          <div class="plcontent">{{ i.askQuestion }}</div>
                          <div class="replay">
                            <span
                              @click="
                                commentsClick(
                                  i.askQuestionId,
                                  (i.comments = !i.comments)
                                )
                              "
                              >评论({{ i.askReplyNum }})</span
                            >
                            <span
                              @click="
                                replyqusClick(
                                  i.askQuestionId,
                                  (i.replyqus = !i.replyqus)
                                )
                              "
                              >回复</span
                            >
                          </div>
                        </div>
                      </div>
                      <!-- 回复 -->
                      <div class="huifu" v-if="i.replyqus">
                        <div class="mt15">
                          <div class="comment-forms">
                            <div style="display: flex; margin-top: 1%">
                              <div
                                style="margin-right: 2%"
                                v-if="value.headPortrait != null"
                              >
                                <el-avatar
                                  :src="value.headPortrait"
                                ></el-avatar>
                              </div>
                              <div v-else style="margin-right: 2%">
                                <el-avatar
                                  size="large"
                                  :src="circleUrl"
                                ></el-avatar>
                              </div>
                              <el-input
                                style="margin-right: 2%"
                                v-model="replyin"
                                placeholder="请输入内容"
                                @input="replyInput"
                              ></el-input>
                              <el-button
                                type="success"
                                :disabled="replydisabled"
                                @click="replyClick"
                                >回复</el-button
                              >
                            </div>
                          </div>
                        </div>
                      </div>
                      <!-- 评论 -->
                      <div
                        class="huifu"
                        v-if="i.comments && i.askReplyNum != 0"
                      >
                        <div
                          class="mt15"
                          v-for="(i, index) in commentsList"
                          :key="index"
                        >
                          <div class="comment-form onehf">
                            <div class="userimg">
                              <a
                                href="javascript:void();"
                                v-if="i.headPortrait != null"
                                ><el-avatar
                                  style="margin-right: 10px"
                                  :src="i.headPortrait"
                                ></el-avatar
                              ></a>
                              <div v-else>
                                <el-avatar
                                  style="margin-right: 10px"
                                  size="large"
                                  :src="circleUrl"
                                ></el-avatar>
                              </div>
                              <div class="userinfo">
                                <p class="name">
                                  <span>{{ i.userName }}</span>
                                </p>
                                <p>{{ i.updateTime }}</p>
                              </div>
                            </div>
                            <div class="plcontent">{{ i.askReply }}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </el-tab-pane>
                <el-tab-pane label="课程考试" name="fourth">
                  <div v-if="courseExamList.length != 0">
                    <div
                      class="bgf4"
                      v-for="(item, index) in courseExamList"
                      :key="index"
                    >
                      <div class="course-li-left" style="font-size: 22px">
                        <i
                          style="margin-right: 10px"
                          class="el-icon-edit-outline"
                        ></i>
                        <div>
                          <div style="display: flex; flex-direction: column">
                            <span class="sketch">{{ item.papersName }}</span>
                            <span class="validity">
                              有效期: {{ item.examinationStartTime }}~{{
                                item.examinationEndTime
                              }}
                              </span
                            >
                          </div>
                        </div>
                      </div>
                      <div style="display: flex; align-items: center">
                        <div class="score">
                          得分：<span>{{ item.getScore }}</span>
                        </div>
                        <el-button type="primary" v-if="item.isConduct==1&&item.answerNumber>item.examinationNum" plain @click="examBegin(item.papersId)">开始考试</el-button>
                        <el-button type="primary" v-if="(item.isSubmitPaper==1&&item.userPapersId)||(item.isConduct==0&&item.isSubmitPaper==0&&item.userPapersId)" plain @click="showExam(item.userPapersId,false,item.papersId)">查看考试</el-button>
                        <el-button type="primary" v-if="item.isConduct==1&&item.isSubmitPaper==0&&item.userPapersId" plain @click="showExam(item.userPapersId,true,item.papersId)">继续考试 </el-button>
                      </div>
                    </div>
                  </div>
                  <div v-else>
                    <el-empty description="暂无考试"></el-empty>
                  </div>
                </el-tab-pane>
                <el-tab-pane label="评价" name="evaluation">
                  <div v-if="appraise == 0 && pingjia">
                    <div class="evaluation">
                      <div class="block">
                        <span class="demonstration">课程内容：</span>
                        <el-rate
                          v-model="evaluationForm.content"
                          @change="evaluationInput"
                        ></el-rate>
                      </div>
                      <div class="block">
                        <span class="demonstration">讲师评价：</span>
                        <el-rate
                          v-model="evaluationForm.lecturer"
                          @change="evaluationInput"
                        ></el-rate>
                      </div>
                      <div class="block">
                        <span class="demonstration">学习评价：</span>
                        <el-rate
                          v-model="evaluationForm.learning"
                          @change="evaluationInput"
                        ></el-rate>
                      </div>
                    </div>
                    <div class="comment-form" style="display: flex">
                      <div v-if="value && value.headPortrait" style="margin-right: 2%">
                         <el-avatar :src="value.headPortrait"></el-avatar>
                      </div>
                     <div v-else style="margin-right: 2%">
                        <el-avatar size="large" :src="circleUrl"></el-avatar>
                      </div>
                      <el-input
                        style="margin-right: 2%"
                        v-model="evaluationForm.input"
                        placeholder="请输入内容"
                        @input="evaluationInput"
                      ></el-input>
                      <el-button
                        :disabled="evaluationdis"
                        type="success"
                        @click="evaluationClick"
                        >评价</el-button
                      >
                    </div>
                  </div>
                  <div v-else>
                    <div
                      class="onehf"
                      v-for="(i, index) in evaluationList"
                      :key="index"
                    >
                      <div class="evaluation" style="padding: 0 30px">
                        <div class="block">
                          <span class="demonstration">课程内容：</span>
                          <el-rate v-model="evaluationForm.content"></el-rate>
                        </div>
                        <div class="block">
                          <span class="demonstration">讲师评价：</span>
                          <el-rate v-model="evaluationForm.lecturer"></el-rate>
                        </div>
                        <div class="block">
                          <span class="demonstration">学习评价：</span>
                          <el-rate v-model="evaluationForm.learning"></el-rate>
                        </div>
                      </div>
                      <div class="userimg">
                        <a
                          href="javascript:void();"
                          v-if="i.headPortrait != null"
                          ><el-avatar
                            style="margin: 10px"
                            :src="i.headPortrait"
                          ></el-avatar
                        ></a>
                        <div v-else>
                          <el-avatar
                            style="margin: 10px 10px"
                            size="large"
                            :src="circleUrl"
                          ></el-avatar>
                        </div>
                        <div style="width: 100%; padding-top: 10px">
                          <p
                            style="
                              display: flex;
                              justify-content: space-between;
                              margin-bottom: 3px;
                              color: #666;
                            "
                          >
                            {{ i.userName }}
                            <span style="font-size: 14px; margin: 0 2%">{{
                              i.createdTime
                            }}</span>
                          </p>
                          <div>{{ i.appraiseContent }}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </el-tab-pane>
                <el-tab-pane label="笔记" name="notes">
                  <div v-if="notesList.length != 0">
                    <div
                      class="onehf"
                      v-for="(i, index) in notesList"
                      :key="index"
                    >
                      <div class="userimg">
                        <a
                          href="javascript:void();"
                          v-if="i.headPortrait != null"
                          ><el-avatar :src="i.headPortrait"></el-avatar
                        ></a>
                        <div v-else>
                          <el-avatar size="large" :src="circleUrl"></el-avatar>
                        </div>
                        <div class="userinfo">
                          <p class="name">
                            <span>{{ i.userName }}</span>
                          </p>
                          <p>
                            {{ i.updateTime }}
                            <span
                              >( {{ i.courseName }}
                              <el-divider direction="vertical"></el-divider>
                              <span style="color: #18cca7">{{
                                i.chapterName
                              }}</span>
                              )</span
                            >
                          </p>
                        </div>
                      </div>
                      <div>
                        <div class="plcontent">{{ i.noteContent }}</div>
                      </div>
                    </div>
                  </div>
                  <div v-else>
                    <el-empty description="暂无笔记"></el-empty>
                  </div>
                </el-tab-pane>
                <el-tab-pane label="直播" name="live">
                  <div class="collapse" v-if="liveRoomList.length!=0">
                    <el-collapse
                      v-model="liveNames"
                      v-for="(item, index) in liveRoomList"
                      :key="index"
                    >
                      <el-collapse-item :name="1">
                        <template slot="title">
                          <img
                            style="margin-right: 10px; width: 10px"
                            src="@/assets/images/onlinecourses/round.png"
                            alt=""
                          />{{item.roomName}}
                        </template>
                        <div class="bgf4">
                          <div class="course-li-left" style="font-size: 22px">
                            <i
                              style="margin-right: 10px"
                              class="el-icon-video-camera"
                            ></i>
                            <div style="display: flex; flex-direction: column">
                            <span class="sketch">{{item.courseName}}</span>
                            <span class="validity"
                              >有效期: {{item.roomStartTime}}~{{item.roomEndTime}}</span
                            >
                          </div>
                          </div>
                          <div>
                            <el-button
                              v-if="item.roomStatus==0"
                              type="primary"
                              plain
                              @click="liveClick(item)"
                              >直播未开始</el-button
                            >
                            <el-button
                              v-else-if="item.roomStatus==1"
                              type="primary"
                              plain
                              @click="liveClick(item)"
                              >正在直播</el-button
                            >
                            <el-button
                              v-else
                              type="info"
                              plain
                              disabled
                              @click="liveClick(item)"
                              >直播已结束</el-button
                            >
                          </div>
                        </div>
                      </el-collapse-item>
                    </el-collapse>
                  </div>
                  <el-empty v-else description="暂无直播"></el-empty>
                </el-tab-pane>
              </el-tabs>
            </div>
          </div>
        </el-col>
        <el-col :span="8">
          <div>
            <!-- 教师简介 -->
            <div class="coursedet-r-person bgwhite shadow">
              <div v-for="(item, i) in introduction" :key="i">
                <div class="avatar">
                  <el-avatar :size="60" :src="item.headPortrait"></el-avatar>
                </div>
                <el-divider>
                  <div><b class="bor"></b></div>
                </el-divider>
                <div class="coursedet-r-cont">
                  <h3>{{ item.nickName }}</h3>
                  <div class="detintro">
                    <span>教龄：{{ item.teachingYear }}年</span>
                    <el-divider direction="vertical"></el-divider>
                    <span>毕业院校：{{ item.schoolCollegeName }}</span>
                    <el-divider direction="vertical"></el-divider>
                    <span>课程数量：{{ item.courseNumber }}</span>
                  </div>
                  <p>{{ item.schoolCollegeName }}</p>
                </div>
              </div>
            </div>
            <!-- 课程推荐 -->
            <div class="coursedet-r-tj bgwhite pad10 shadow mt15">
              <div slot="header" class="clearfix">
                <i class="el-icon-caret-right"></i>
                <span>课程推荐</span>
              </div>
              <div class="divider">
                <el-divider></el-divider>
              </div>
              <div v-if="recommendedList.length != 0">
                <div
                  class="recommended"
                  v-for="(item, index) in recommendedList"
                  :key="index"
                >
                  <img style="width: 35%" :src="item.facePic" alt="" />
                  <div style="width: 40%; margin-left: 3%; padding-top: 10px">
                    <p class="curriculum">{{ item.courseName }}</p>
                    <div style="padding-top: 30px" class="cktj">
                      <div style="color: #666; padding-bottom: 10px">
                        {{ item.teacherName }}
                      </div>
                      <i class="el-icon-user"></i>
                      <span>{{ item.joinNumber }}人参加</span>
                    </div>
                  </div>
                </div>
              </div>
              <div v-else>
                <el-empty :image-size="150" description="暂无推荐" />
              </div>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
    <!-- <div class="page mt15 mb20">
      <el-pagination
        background
        layout="prev, pager, next"
        :page-size="pageSize"
        :total="count"
        :current-page="page"
        @current-change="handleCurrentChange"
      >
      </el-pagination>
    </div> -->
    <!-- 购买课程弹框 -->
    <el-dialog
      title="购买"
      :visible.sync="dialogVisible"
      width="500px">
      <div>
        <el-row>
          <ul class="flex-between zfmethod1">
            <li class="checked">
              <div class="gou">
                <i class="el-icon-check"></i>
              </div>
              <img src="@/assets/images/onlinecourses/weixin.png" alt="">
            </li>
          </ul>
          <div>
            <div class="deduction flex-between">
              <span>
                <img src="@/assets/images/onlinecourses/jifen.png" alt="">可使用 {{userPoints.userPointsSum}} 积分抵扣 {{userPoints.userIntegralPrice}} 元 
              </span>
              <el-checkbox v-model="checked" @change="handleCheckAllChange"></el-checkbox>
            </div>
            <div class="priceoff flex-between">
              <div class="priceoff-l">
                <span>商品金额： ￥{{userPoints.sumPrice}}</span>
                <span>积分抵扣：￥{{userPoints.userIntegral}}</span>
              </div>
              <div class="priceoff-r">
                实付金额：
                <b>￥{{sumPriceList}}</b>
              </div>
            </div>
          </div>
          
        </el-row>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="payClick">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 口令弹框 -->
      <el-dialog
        title="课程口令"
        :visible.sync="centerDialogVisible"
        width="30%"
        :close-on-click-modal="false">
        <div>
          <el-form ref="formOne" :model="formOne" label-width="90px">
            <el-form-item
              label="考试口令:"
              prop="examinationPassword"
              :rules="[{ required: true, message: '口令不能为空' }]"
            >
              <el-input
                v-model="formOne.examinationPassword"
                clearable
                placeholder="请输入考试口令"
              >
              </el-input>
            </el-form-item>
          </el-form>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click="commamdClick('formOne')">确 定</el-button>
        </span>
      </el-dialog>
    <!-- 添加字段弹框 -->
      <el-dialog
        title="信息展示"
        :visible.sync="information"
        width="60vh"
        :close-on-click-modal="false"
        >
        <div>
          <el-form :model="examInfo" ref="examForms">
            <el-table :data="examInfo.data " style="width: 100%">
              <el-table-column prop="paramName" align="center" label="名称">
              </el-table-column>
              <el-table-column prop="paramVal" label="值" align="center">
              <template slot-scope="scope">
               <!-- <div>{{scope.row}}</div> -->
                <el-form-item
                  v-if="scope.row.paramFormat == 1 && scope.row.isRequired == 1"
                  :prop="'data.' + scope.$index + '.paramVal'"
                  :rules="[
                    {
                      required: true,
                      message: '数字不能为空',
                      trigger: 'blur',
                    },
                  ]"
                >
                  <el-input
                    v-model="scope.row.paramVal"
                    maxlength="50"
                    type="number"
                    placeholder="请输入值"
                  ></el-input>
                </el-form-item>
                <el-form-item
                  v-if="scope.row.paramFormat == 0 && scope.row.isRequired == 1 "
                  :prop="'data.' + scope.$index + '.paramVal'"
                  :rules="[
                    {
                      required: true,
                      message: '内容不能为空',
                      trigger: 'blur',
                    },
                  ]"
                >
                  <el-input
                    v-model="scope.row.paramVal"
                    maxlength="50"
                    placeholder="请输入值"
                  ></el-input>
                </el-form-item>
                <el-form-item
                  :prop="'data.' + scope.$index + '.paramVal'"
                  v-if="
                    1 == scope.row.paramFormat && scope.row.isRequired == 0
                  "
                >
                  <el-input
                    v-model="scope.row.paramVal"
                    maxlength="50"
                    type="number"
                    placeholder="请输入值"
                  ></el-input>
                </el-form-item>
                <el-form-item
                  :prop="'data.' + scope.$index + '.paramVal'"
                  v-if="0 == scope.row.paramFormat && scope.row.isRequired == 0
                  "
                >
                  <el-input
                    v-model="scope.row.paramVal"
                    maxlength="50"
                    placeholder="请输入值"
                  ></el-input>
                </el-form-item>
              </template>
            </el-table-column>
            </el-table>
          </el-form>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click="informationSubment">确 定</el-button>
        </span>
      </el-dialog>
  </div>
</template>

<script>
// import BreadcrumbNav from '@/components/breadCrumbs/Breadcrumb'
export default {
  // components:{ BreadcrumbNav },
  data() {
    return {
      token: localStorage.getItem("pc_token"),
      value: JSON.parse(localStorage.getItem("pc_data")),
      circleUrl:
        "https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png",
      activeName: "first",
      shareImgList:[
        {img:require('@/assets/images/onlinecourses/weibo.png')},
        {img:require('@/assets/images/onlinecourses/kongjian.png')},
        {img:require('@/assets/images/onlinecourses/dou.png')},
        {img:require('@/assets/images/onlinecourses/tie.png')},
      ],
      evaluationForm: {
        content: null,
        lecturer: null,
        learning: null,
        input: "",
      },
      formOne: { //口令字段
        examinationPassword: ''
      },
      questionsinput: "",
      replyin: "",
      detail: [],
      introduction: [],
      numberList: [],
      practiceList: [],
      questionList: [],
      commentsList: [],
      notesList: [],
      courseExamList: [],
      recommendedList: [],
      userPoints:[],
      liveRoomList:[],
      fillParams:[],//字段
      examInfo: {},
      disabled: true, //提问
      replydisabled: true, // 回复
      evaluationdis: true, //评价
      pingjia: true,
      dialogVisible:false,//购买弹框
      checked: false,
      information: false, // 字段弹框
      centerDialogVisible: false, // 口令弹框
      askQuestionIds: "",
      appraise: "", //判断学员是否评论
      sumPriceList:'',
      fieldStr: '',
      activityId:'',
      jifen:0,
      chapterList: [],
      evaluationList: [],
      buy: "",
      activeNames: ["1"],
      liveNames:["1"],
      pageSize: 9,
      page: 1,
      count: 0,
    };
  },
  mounted() {
    this.getFrontCourseLabelPageApi();
    this.getCourseDetailApi();
    this.getFrontCourseTeacherApi();
    this.getFrontCourseStatisticApi();
    this.getFrontCourseField();
    this.getCourseAppraiseListApi();
    this.queryCourseAskQuestionList();
    this.getUserCourseIsAppraise();     
  },
  methods: {
    // 课程报名
    enrolmentClick(){
      if(this.detail.joinWay == 0){ //公开课程无字段
        if(this.fillParams == ''){
          this.getCourseDetailApi()
          this.joinCourse()
        }else if(this.fillParams != ''){
          this.information = true
        }
      }else if(this.detail.joinWay == 2){ //指定学员
        if(this.fillParams == ''){
          this.getCourseDetailApi()
          this.joinCourse()
        }else if(this.fillParams != ''){
          this.information = true
        }
      }
      else if(this.detail.joinWay == 1){ //口令课程
        this.centerDialogVisible = true
      }
    },
    // 获取课程字段
    getFrontCourseField(){
      
      this.$api.getFrontCourseField({
          courseId: this.$route.query.id,
          token:this.token,
        }).then(res => {
          if(res.code == 10000){
            this.fillParams = res.data 
            this.examInfo = res
            
        }
      })
    },
    // 口令输入弹框确定按钮
    commamdClick(formName){
      this.$refs[formName].validate((valid) => {
        if(valid){
          if(this.fillParams != ''){
            this.information = true
            this.centerDialogVisible = false
          }else{
            this.centerDialogVisible = false
            this.getCourseDetailApi()
            this.joinCourse()
            
          }
        }
      })
    },
    // 字段展示确定按钮
    informationSubment(){
      this.$refs.examForms.validate((valid) => {
        if(valid){
          this.information = false
          this.getCourseDetailApi()
          this.joinCourse()         
        }else {
          this.$message.warning('请填写必填项')
        }
      })
    },
    // 购买课程
    buyClick(){
      this.dialogVisible = true
    },
    // 章节练习进入学习按钮
    intoStudy(courseId,chapterId,resourceId,resourceType,activityId){
      if(this.token == null){
        this.$message.warning('请先登录')
        return
      }else if(this.detail.isJoin == 0 && this.detail.joinWay !=3){
        this.$message.warning('请先加入课程')
        return
      }else if(this.detail.courseState==2 && this.detail.joinWay !=3){
        this.$message.warning('课程已结束')
        return
      }else if(this.detail.isJoin == 0 && this.detail.joinWay ==3){
        this.$message.warning('请先购买课程')
        return
      }else if(this.detail.courseState==2 && this.detail.joinWay ==3){
        this.$message.warning('课程已结束')
        return
      }else{
        this.$router.push({
        path:'/course/kcvideoplayer',
        query:{courseId:courseId,chapterId:chapterId,resourceId:resourceId,resourceType:resourceType,activityId:activityId}
      })
      }
     
    },
    // 章节练习预览按钮
    previewClick(id){
      if(this.token == null){
        this.$message.warning('请先登录')
        return
      }else if(this.detail.isJoin == 0 && this.detail.joinWay !=3){
        this.$message.warning('请先加入课程')
        return
      }else if(this.detail.courseState==2 && this.detail.joinWay !=3){
        this.$message.warning('课程已结束')
        return
      }else if(this.detail.isJoin == 0 && this.detail.joinWay ==3){
        this.$message.warning('请先购买课程')
        return
      }else if(this.detail.courseState==2 && this.detail.joinWay ==3){
        this.$message.warning('课程已结束')
        return
      }else{
        this.$router.push({
        path:'/course/viewpreview',
        query:{resourceId:id}
      })
      }
      
    },
    // 章节练习播放按钮
    playClick(id){
      if(this.token == null){
        this.$message.warning('请先登录')
        return
      }else if(this.detail.isJoin == 0 && this.detail.joinWay !=3){
        this.$message.warning('请先加入课程')
        return
      }else if(this.detail.courseState==2 && this.detail.joinWay !=3){
        this.$message.warning('课程已结束')
        return
      }else if(this.detail.isJoin == 0 && this.detail.joinWay ==3){
        this.$message.warning('请先购买课程')
        return
      }else if(this.detail.courseState==2 && this.detail.joinWay ==3){
        this.$message.warning('课程已结束')
        return
      }else{
        this.getMyCourseResource(id)
      }
      
    },
    getMyCourseResource(id){
      this.$api.getMyCourseResource({
        resourseId:id,
        token:this.token
      }).then(res => {
        if(res.code == 10000){
          window.open(res.data.resourceUrl)
        }
      })
    },
    // 课后练习开始练习按钮
    startPracticing(id){
      if(this.token == null){
        this.$message.warning('请先登录')
        return
      }else if(this.detail.isJoin == 0 && this.detail.joinWay !=3){
        this.$message.warning('请先加入课程')
        return
      }else if(this.detail.courseState==2 && this.detail.joinWay !=3){
        this.$message.warning('课程已结束')
        return
      }else if(this.detail.isJoin == 0 && this.detail.joinWay ==3){
        this.$message.warning('请先购买课程')
        return
      }else if(this.detail.courseState==2 && this.detail.joinWay ==3){
        this.$message.warning('课程已结束')
        return
      }else{
         let _courseId = this.$route.query.id??''
         let _activityId= this.$route.query.activityId ??''
         this.$router.push({
        path:'/Theonlinetest/PracticeTests/viewItem',
        query:{papersId:id,isStart:1,isSubmitExam:true,curriculumResourceId:_courseId,activityId:_activityId}
      })
      }
    
    },
    showPracticing(id,isConducts,papersId){
        let _courseId = this.$route.query.id??''
        let _activityId= this.$route.query.activityId ??''
        if(isConducts){
          this.$router.push({
            path:'/Theonlinetest/PracticeTests/viewItem',
            query:{userPapersId:id,papersId:papersId,isPreview:true,curriculumResourceId:_courseId,activityId:_activityId}
          })
        }else{
          this.$router.push({
            path:'/Theonlinetest/PracticeTests/viewItem',
            query:{userPapersId:id,papersId:papersId,isView:true,curriculumResourceId:_courseId,activityId:_activityId}
          })
        }
    },
    // 课程考试开始考试按钮
    examBegin(id){
      if(this.token == null){
        this.$message.warning('请先登录')
        return
      }else if(this.detail.isJoin == 0 && this.detail.joinWay !=3){
        this.$message.warning('请先加入课程')
        return
      }else if(this.detail.courseState==2 && this.detail.joinWay !=3){
        this.$message.warning('课程已结束')
        return
      }else if(this.detail.isJoin == 0 && this.detail.joinWay ==3){
        this.$message.warning('请先购买课程')
        return
      }else if(this.detail.courseState==2 && this.detail.joinWay ==3){
        this.$message.warning('课程已结束')
        return
      }else{
        let _courseId = this.$route.query.id??''
        let _activityId= this.$route.query.activityId ??''
        this.$router.push({
        path:'/Theonlinetest/TestPaper/viewItem',
        query:{papersId:id,isStart:1,isSubmitExam:true,curriculumResourceId:_courseId,activityId:_activityId}
      })
      }
      
    },
    showExam(id,isConducts,papersId){
        let _courseId = this.$route.query.id??''
        let _activityId= this.$route.query.activityId ??''
        if(isConducts){
          this.$router.push({
            path:'/Theonlinetest/TestPaper/viewItem',
            query:{userPapersId:id,papersId:papersId,isPreview:true,curriculumResourceId:_courseId,activityId:_activityId}
          })
        }else{
          this.$router.push({
            path:'/Theonlinetest/TestPaper/viewItem',
            query:{userPapersId:id,papersId:papersId,isView:true,curriculumResourceId:_courseId,activityId:_activityId}
          })
        }
      
    },
    // 获取课程标签
    getFrontCourseLabelPageApi() {
      this.$api
        .getFrontCourseLabelPageApi({
          courseId: this.$route.query.id,
          token: this.token,
        })
        .then((res) => {
          if (res.code == 10000) {
            res.data.forEach((item) => {
              let labelNum = item.labelNum;
              let labelId = item.labelId;
              this.getFrontLabelCoursePageApi(labelNum, labelId);
            });
          }
        });
    },
    // 获取标签课程列表
    getFrontLabelCoursePageApi(labelNum, labelId) {
      this.$api
        .getFrontLabelCoursePageApi({
          labelNum: labelNum,
          labelId: labelId,
          channelId: this.$http.channelId,
          token: this.token,
        })
        .then((res) => {
          if (res.code == 10000) {
            this.recommendedList = res.data;
          }
        });
    },
    // 课程详情
    getCourseDetailApi() {
      this.$api
        .getCourseDetailApi({
          courseId: this.$route.query.id,
          activityId: this.$route.query.activityId,
          token: this.token,
        })
        .then((res) => {
          if (res.code == 10000) {
            this.detail = res.data.detail;
            this.chapterList = res.data.chapterList;
            this.activityId = res.data.activityId
            if(this.detail.joinWay == 3){
              this.subTrainCourseOrder()
            }              
          }
        });
    },
    // 字段
    inquiryGoodsStr(){
      const List = this.fillParams.map((item) => {
        return {
          paramsId:item.paramsId,
          paramVal:item.paramVal,
          paramName:item.paramName,
          isRequired:item.isRequired,
          paramFormat:item.paramFormat,
          displaySort:item.displaySort,
        }
      })
      this.fieldStr = JSON.stringify(List)
    },
    // 学员加入课程
    joinCourse(){
      this.inquiryGoodsStr()
      this.$api.joinCourse({
        courseId: this.$route.query.id,
        pwd: this.formOne.examinationPassword || '',
        fieldStr: this.fieldStr,
        token: this.token
      }).then(res => {
        if(res.code == 10000){
          this.$message.success('加入课程成功')
          location.reload()
        }
      })
    },
    getFrontCourseStatisticApi() {
      this.$api
        .getFrontCourseStatisticApi({
          courseId: this.$route.query.id,
          token: this.token,
        })
        .then((res) => {
          if (res.code == 10000) {
            this.numberList = res.data;
          }
        });
    },
    // 讲师简介接口
    getFrontCourseTeacherApi() {
      this.$api
        .getFrontCourseTeacherApi({
          courseId: this.$route.query.id,
          token: this.token,
        })
        .then((res) => {
          if (res.code == 10000) {
            this.introduction = res.data;
          }
        });
    },
    // 课后练习
    getFrontCoursePracticePageApi() {
      this.$api
        .getFrontCoursePracticePageApi({
          page: this.page,
          limit: this.pageSize,
          courseId: this.$route.query.id,
          token: this.token,
        })
        .then((res) => {
          if (res.code == 10000) {
            this.practiceList = res.data.data;
            this.count = res.data.count;
          }
        });
    },
    // 在线提问接口
    queryCourseAskQuestionList() {
      this.$api
        .queryCourseAskQuestionList({
          askTime: "desc",
          courseId: this.$route.query.id,
          token: this.token,
        })
        .then((res) => {
          if (res.code == 10000) {
            this.questionList = res.data;
            this.questionList.map((item, index) => {
              this.$set(item, "replyqus", false);
              this.$set(item, "comments", false);
            });
          }else{
            // this.$message.warning('')
          }
        });
    },
    // 提问保存接口
    saveTrainCourseAskQuestion() {
      this.$api
        .saveTrainCourseAskQuestion({
          courseId: this.$route.query.id,
          askQuestion: this.questionsinput,
          token: this.token,
        })
        .then((res) => {
          if (res.code == 10000) {
            this.$message.success("问题已经提交，等待讲师解答");
            this.queryCourseAskQuestionList();
            this.questionsinput = "";
          }
        });
    },
    // 回复接口
    queryTrainCourseAskReplyList() {
      this.$api
        .queryTrainCourseAskReplyList({
          askQuestionId: this.askQuestionIds,
          token: this.token,
        })
        .then((res) => {
          if (res.code == 10000) {
            this.commentsList = res.data;
          }
        });
    },
    // 回复保存接口
    saveTrainCourseAskReply() {
      this.$api
        .saveTrainCourseAskReply({
          askQuestionId: this.askQuestionIds,
          askReply: this.replyin,
          token: this.token,
        })
        .then((res) => {
          if (res.code == 10000) {
            this.$message.success("回复成功");
            this.replyin = "";
          }
        });
    },
    // 在线提问按钮
    questionsClick() {
      if(this.detail.isJoin == 0){
        this.$message.warning('请先加入课程')
        return
      } 
      if(this.token!=null){
        this.saveTrainCourseAskQuestion();
      }else{
        this.$message.warning('请先登录')
      }
      
    },
    // 回复按钮
    replyClick() {
      this.saveTrainCourseAskReply();
      this.queryTrainCourseAskReplyList();
      this.queryCourseAskQuestionList();
      this.replyqus == false;
      this.comments = false;
    },
    // 回复显隐按钮
    replyqusClick(askQuestionId,replyqus) {
      this.askQuestionIds = askQuestionId;
      if (replyqus == true) {
        this.queryTrainCourseAskReplyList();
      }
      this.comments = false;
    },
    // 评论显隐按钮
    commentsClick(askQuestionId,comments) {
      this.askQuestionIds = askQuestionId;
      if (comments == true) {
        this.queryTrainCourseAskReplyList();
      }
    },
    // 提问输入框
    tiwen() {
      if (this.questionsinput != "") {
        this.disabled = false;
      } else {
        this.disabled = true;
      }
    },
    // 回复输入框
    replyInput() {
      if (this.replyin != "") {
        this.replydisabled = false;
      } else {
        this.replydisabled = true;
      }
    },
    // 课程考试信息列表
    getCourseExaminationPageApi() {
      this.$api
        .getCourseExaminationPageApi({
          page: 1,
          limit: 12,
          courseId: this.$route.query.id,
          token: this.token,
        })
        .then((res) => {
          if (res.code == 10000) {
            // let _data = res?.data?.data??[]
            this.courseExamList = res?.data?.data??[]
            // this.courseExamList = _data.filter((item) => {
            //   item.isEnd = item.isConduct
            //   return item
            // });
            // console.log(this.courseExamList[0])
          }
        });
    },
    // 笔记列表接口
    queryTrainCourseNoteList() {
      this.$api
        .queryTrainCourseNoteList({
          courseId: this.$route.query.id,
          token: this.token,
        })
        .then((res) => {
          if (res.code == 10000) {
            this.notesList = res.data.data;
          }
        });
    },
    evaluationInput() {
      if (
        this.evaluationForm.content != 0 &&
        this.evaluationForm.lecturer != 0 &&
        this.evaluationForm.learning != 0 &&
        this.evaluationForm.input != ""
      ) {
        this.evaluationdis = false;
      } else {
        this.evaluationdis = true;
      }
    },
    // 获取学员是否评论
    getUserCourseIsAppraise() {
      this.$api
        .getUserCourseIsAppraise({
          courseId: this.$route.query.id,
          token: this.token,
        })
        .then((res) => {
          if (res.code == 10000) {
            this.appraise = res.data;
          }
        });
    },
    // 获取评价列表
    getCourseAppraiseListApi() {
      this.$api
        .getCourseAppraiseListApi({
          page: 1,
          limit: 20,
          timeType: 1,
          courseId: this.$route.query.id,
          token: this.token,
        })
        .then((res) => {
          if (res.code == 10000) {
            this.evaluationList = res.data.data;
            this.evaluationList.forEach((item) => {
              this.evaluationForm.content = item.courseStar;
              this.evaluationForm.lecturer = item.lecturerStar;
              this.evaluationForm.learning = item.studyStar;
            });
          }
        });
    },
    // 评价接口
    addCourseAppraise() {
      this.$api
        .addCourseAppraise({
          appraiseContent: this.evaluationForm.input,
          courseStar: this.evaluationForm.content,
          studyStar: this.evaluationForm.learning,
          lecturerStar: this.evaluationForm.lecturer,
          courseId: this.$route.query.id,
          token: this.token,
        })
        .then((res) => {
          if (res.code == 10000) {
            this.$message.success("评价成功");
            this.pingjia = false;
            this.evaluationForm = "";
          }
        });
    },
    // 评价按钮
    evaluationClick() {
      if(this.detail.isJoin == 0){
        this.$message.warning('请先加入课程')
        return
      }
      if(this.token!=null){
        this.addCourseAppraise();
      }else{
        this.$message.warning('请先登录')
      }  
    },
    // 进入直播按钮
    liveClick(item){
       this.$router.push({
            path:'/videoplayerlive',
            query:{id:item.liveRoomId}
        })
    },
    // 课程直播列表
    queryLiveRoomListFront(){
      this.$api.queryLiveRoomListFront({
        page:1,
        limit:this.pageSize,
        courseId: this.$route.query.id,
        token: this.token,
      }).then(res => {
        if(res.code == 10000){
          if(res.data.data.roomStatus == 0){
            this.$message.warning('直播未开始')
            return
          }
          this.liveRoomList = res.data.data         
        }
      })
    },
    // 是否使用积分
    handleCheckAllChange(val){
      console.log(val);
      if(val == true){
        this.jifen = 1
      }else{
        this.jifen = 0
      }
      this.subTrainCourseOrder()
    },
    // 购买加入课程
    subTrainCourseOrder(){
      this.$api.subTrainCourseOrder({
        token:this.token,
        courseId: this.$route.query.id,
        isPoints:this.jifen
      }).then(res => {
        if(res.code == 10000){
          this.userPoints = res.data.userPoints
          this.sumPriceList = res.data.sumPrice
        }
      })
    },
    // 购买确定按钮
    submitTrainCourseOrder(){
      this.$api.submitTrainCourseOrder({
        token:this.token,
        courseId: this.$route.query.id,
        isPoints:this.jifen
      }).then(res => {
        if(res.code == 10000){
          let orderId = res.data.trainCourseOrder.orderId
          this.completePayState(orderId)
        }
      })
    },
    completePayState(orderId){
      this.$api.completePayState({
        orderId:orderId,
        payWay:0,
        token:this.token,
      }).then(res => {
        if(res.code == 10000){
          this.dialogVisible = false
          this.getCourseDetailApi();
        }
      })
    },
    //支付确定按钮
    payClick(){
      this.submitTrainCourseOrder()
    },
    handleClick(tab, event) {
      console.log(tab, event);
      if (tab.name == "second") {
        this.getFrontCoursePracticePageApi();
      } else if (tab.name == "fourth") {
        this.getCourseExaminationPageApi();
      } else if (tab.name == "notes") {
        this.queryTrainCourseNoteList();
      }else if(tab.name == "evaluation"){
        this.getCourseAppraiseListApi()
      }else if(tab.name == "live"){
        this.queryLiveRoomListFront()
      }
    },
  },
};
</script>

<style scoped lang="scss">
.coursedet-img {
  position: relative;
  width: 100%;
  overflow: hidden;
  background: rgba(0, 0, 0, 0.3);
  margin-top: 15px;
  display: inline-block;
  clear: both;

  .coursedet-bot {
    position: absolute;
    width: 100%;
    padding: 10px;
    background: rgba(0, 0, 0, 0.5);
    bottom: 0;
    .coursedet-imgl p.coursedet-tit {
      color: #fff;
      font-size: 18px;
      font-weight: 700;
      margin-bottom: 10px;
    }
    .coursedet-imgl p.coursedet-tit em {
      margin-left: 10px;
      color: red;
    }
    .coursedet-imgl p {
      color: #e0e0e0;
      font-size: 14px;
    }
    .coursedet-imgl p span {
      margin-right: 25px;
    }
    .coursedet-imgbut button.signed {
      background: #333;
      color: #ccc;
      cursor: inherit;
    }
    .coursedet-imgbut button {
      color: #fff;
      padding: 10px 25px;
      font-size: 16px;
      border: 0 none;
    }
  }
  
}
.flex-between {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
}
// 购买弹框样式
.zfmethod1 li {
    width: 48%;
    position: relative;
    margin: 0 auto;
}
.checked {
    border: 2px solid red;
}
.zfmethod1 li.checked .gou {
    display: block;
}
.zfmethod1 li .gou {
    position: absolute;
    right: -2px;
    bottom: -1px;
    font-size: 22px;
    width: 0;
    height: 0;
    border-bottom: 25px solid red;
    border-left: 25px solid transparent;
}
.zfmethod1 li .gou i{
  position: absolute;
  right: 0;
  bottom: -26px;
  font-size: 17px;
  color: #fff;
}
.zfmethod1 img {
    float: left;
    width: 100%;
}
.deduction {
    padding: 25px 0 15px;
    align-items: center;
}
.deduction img {
    width: 20px;
    height: 20px;
    vertical-align: middle;
    margin-right: 10px;
}
.priceoff-l span {
    margin-left: 15px;
}
.priceoff-r b {
    color: red;
    font-size: 18px;
    font-weight: 700;
}
.coursedet-listimg {
  width: 100%;
  overflow: hidden;
}
.coursedet-listimg img {
  width: 100%;
  height: 3.2rem;
}
.bgpng {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.chargetxt {
  width: 45%;
}
.chargetxt h3 {
  font-size: 22px;
  margin-bottom: 15px;
  font-weight: 700;
}
.line35 {
  line-height: 30px;
  color: #555;
  font-size: 15px;
}
.chargetxt .line35 span {
  margin-right: 25px;
}
.chargetxt p.label {
  padding: 10px 0;
}
.chargepay {
  display: flex;
  margin-top: 10px;
}
.flex-between {
  justify-content: space-between;
  align-items: center;
  align-content: center;
}
.pad5 {
  padding: 5px;
}
.red {
  display: inline-block;
  margin-top: 6px;
  color: red;
  font-size: 24px;
}
.radius5 {
  border-radius: 5px;
}
.flexstart {
  display: flex;
  justify-content: flex-start;
}
.pad20 {
  padding: 20px;
}
.shadow {
  box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
}
.bgwhite {
  display: inline-block;
  background-color: #fff;
  width: 100%;
}
.chargeimg {
  // width: 55%;
  position: relative;
  margin-right: 25px;
}
.coursedetail {
  width: 100%;
  display: inline-block;
  clear: both;

  .mb15 {
    margin-bottom: 15px;
  }
  .coursedet-l-t {
    font-weight: 700;
    font-size: 16px;
    padding: 5px 0px 10px;
  }
  .coursedet-l-tw {
    display: flex;
    .coursedet-l-twtxt {
      padding: 0px 15px;
    }
  }
  .pad20 {
    padding: 20px;
  }
  .evaluation {
    display: flex;
    justify-content: space-between;
    .block {
      display: flex;
      .demonstration {
        color: #000;
        font-size: 14px;
      }
    }
  }
  .active {
    color: #18cca7;
  }
  .comment-form {
    max-width: 100%;
    padding: 0.1rem 0.1964rem;
    display: flex;
    background-color: rgb(250, 251, 252);
    border-radius: 3px;
    margin-top: 3%;
  }
  .onehf {
    padding: 15px 10px 10px;
    background: #fcfcfc;
    display: flex;
    flex-direction: column;
  }
  .replay span {
    margin: 0 10px;
    cursor: pointer;
    color: #666;
  }
  .replay span:hover {
    color: #18cca7;
  }
  .huifu {
    padding: 0 0 0 30px;
  }
  .comment-forms {
    max-width: 100%;
    padding: 0.1rem 0.1664rem;
    margin-bottom: 15px;
    background-color: #fafbfc;
    border-radius: 3px;
  }
  .plcontent {
    padding-top: 10px;
  }
  .userimg {
    display: flex;
  }
  .userinfo .name {
    font-size: 16px;
  }
  .userinfo p {
    font-size: 14px;
    color: #666;
  }
  .replay {
    padding: 5px 15px;
    text-align: right;
  }
  .coursedet-r-person {
    min-height: 20px;
    .avatar {
      text-align: center;
      padding: 30px 0px 5px;
    }
    .bor {
      position: absolute;
      width: 100px;
      height: 2px;
      left: 50%;
      margin-left: -50px;
      background-color: #3ce4bc;
    }
    .coursedet-r-cont h3 {
      text-align: center;
      color: #3ce4bc;
      font-size: 16.38px;
    }
    .coursedet-r-cont p {
      padding: 0px 10px 15px;
      font-size: 14px;
    }
    .detintro {
      text-align: center;
      padding: 15px 0px;
    }
    .detintro span {
      color: rgb(102, 102, 102);
      font-size: 14px;
    }
  }
  .pad10 {
    padding: 10px 5px;
  }
  .cktj {
    color: #000;
    font-size: 12px;
  }
  .bgf4 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 20px;
    margin-bottom: 10px;
    font-size: 16px;
    border-radius: 5px;
    background: #f4f3f3;
    .course-li-left {
      display: flex;
      font-size: 22px !important;
    }
    .sketch {
      font-size: 16px;
      cursor: pointer;
    }
    .sketch:hover {
      color: #3ce4bc;
    }
    .validity {
      font-size: 14px;
      color: #666;
    }
    .score {
      font-size: 16px;
      cursor: pointer;
      margin-right: 0.1rem;
    }
    .score span {
      color: #ff0000;
    }
  }
  .curriculum {
    color: #333;
    font-size: 14px;
    cursor: pointer;
  }
  .curriculum:hover {
    color: #3ce4bc;
  }
  .zxtiwen{
    height: 2.5rem;
    overflow-x: hidden;
    overflow-y: auto;
    // overflowX: hidden;
    // overflowY: auto;
  }
}
.recommended {
  display: flex;
  border-bottom: 1px dashed #ccc;
  padding: 10px;
}
.recommended:last-child {
  border-bottom: 1px dashed #fff;
}
.mt15 {
  margin-top: 15px;
}
.clearfix ::v-deep .el-icon-caret-right {
  font-size: 25px;
}
.divider::v-deep .el-divider--horizontal {
  margin: 5px 0;
}
.collapse::v-deep .el-collapse {
  border-top: 0px solid #ebeef5;
  border-bottom: 0px solid #ebeef5;
}
.collapse::v-deep .el-collapse-item__header {
  font-size: 14px;
  font-weight: 700;
}
.share::v-deep .el-button--text {
  color: #fff;
}
</style>